import React, { useState } from 'react'
import i18n from '../util/localization'
import { useGetCustomerGroupsDataQuery } from '../api/groups'
import style from './GroupsDropdown.scss'

const GroupsDropdown = ({ updateCustomerGroup }: { updateCustomerGroup: Function }) => {
  const [selectedGroup, setSelectedGroup] = useState('groupFilterAll')
  const { isLoading, isError, isSuccess, data } = useGetCustomerGroupsDataQuery(null)
  const handleGroupChange = (newGroup: string) => {
    setSelectedGroup(newGroup)
    updateCustomerGroup(newGroup)
  }

  return (
    <>
      {(!isError && !isLoading) && <div className={style['groups-dropdown-container']}>
        <select id="customer-groups-dropdown" className={`form-control ${style['groups-dropdown']}`} onChange={event => handleGroupChange(event.target.value)} value={selectedGroup}>
          <option key='groupFilterAll' value='groupFilterAll'>{i18n.t('AllaGrupper')}</option>
          {isSuccess && [...data]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((groupFilter, index) => (
            <option key={`groupFilter_${index}`} value={groupFilter.externalEquipmentGroupReference}>{groupFilter.name}</option>
            ))}
        </select>
      </div>
      }
    </>
  )
}

export default GroupsDropdown
